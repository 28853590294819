import { FC, useContext } from 'react'

import cx from 'classnames'

import Team from 'components/Team'
import noTeamsIcon from 'assets/icons/team.png'
import { ITeamBase, TeamsContextType } from 'models'
import { MESSAGE_NO_TEAMS_CREATED } from 'constants/teams'
import { TeamsContext } from 'contexts/teams-context'

import './Teams.scss'

interface ITeamsProps {
  className?: string
}

const Teams: FC<ITeamsProps> = ({ className }) => {
  const { teams } = useContext(TeamsContext) as TeamsContextType

  return (
    <div className={cx('teams', className)}>
      {teams.length ? (
        <ul className='teams-table' id='teams-table' style={{ display: 'block' }}>
          <li className='teams-table-header'>
            <div className='teams-table-header-teams'>
              <span>Teams</span>
            </div>
            <div className='teams-table-header-subscription'>
              <span>Subscription Status</span>
            </div>
          </li>
          {teams.map((team: ITeamBase, index: number) => {
            return <Team key={`${index}-${team.name}`} team={team} index={index} />
          })}
        </ul>
      ) : (
        <div className='teams-info-no-data'>
          <img src={noTeamsIcon} alt='team' />
          <span>{MESSAGE_NO_TEAMS_CREATED}</span>
        </div>
      )}
    </div>
  )
}

export default Teams
