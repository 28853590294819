import { createContext, FC, useEffect, useState } from 'react'

import { AxiosError } from 'axios'

import { IProps, ITeamBase, TeamsContextType } from 'models'
import { getAllTeams } from 'services/Teams'

export const TeamsContext = createContext<TeamsContextType | null>(null)

const TeamsProvider: FC<IProps> = ({ children }) => {
  const [teams, setTeams] = useState<ITeamBase[]>([])

  useEffect(() => {
    if (teams.length === 0) {
      getAllTeams()
        .then((teams: ITeamBase[]) => {
          updateTeams(teams)
        })
        .catch((error: AxiosError) => {
          console.log(error)
        })
    }
  }, [])

  const updateTeams = (state: ITeamBase[]) => {
    setTeams(state)
  }

  return <TeamsContext.Provider value={{ teams }}>{children}</TeamsContext.Provider>
}

export default TeamsProvider
