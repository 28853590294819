import { FC, useContext } from 'react'

import cx from 'classnames'

import Avatar from 'components/Avatar'
import { GlobalContext } from 'contexts/global-context'
import { GlobalContextType } from 'models'

import './Account.scss'
import Pill from 'components/Pill'

interface IAccountProps {
  className?: string
}

const Account: FC<IAccountProps> = ({ className }) => {
  const { user } = useContext(GlobalContext) as GlobalContextType

  return (
    <div className={cx('account', className)}>
      <div className='account-visual'>
        <Pill className='account-visual-box'>
          <div className='account-visual-box-inside'>
            <Avatar
              className='account-visual-box-inside-avatar'
              image={user.photoUrl}
              height='80px'
              width='80px'
            />
            <div className='account-visual-box-inside-text'>
              <p className='account-visual-box-inside-text-title'>{`${user.firstName} ${user.lastName}`}</p>
            </div>
          </div>
        </Pill>
      </div>
    </div>
  )
}

export default Account
