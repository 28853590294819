import { AxiosError } from 'axios'
import cx from 'classnames'
import { setCookie } from 'typescript-cookie'
import { Dispatch, FC, SetStateAction } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faApple } from '@fortawesome/free-brands-svg-icons'
import AppleSignin, { AppleAuthResponse } from 'react-apple-signin-auth'

import {
  ACCESS_CLIENT_TOKEN_KEY,
  OAUTH2_APPLE_CLIENT_ID,
  OAUTH2_APPLE_REDIRECT_URI,
  OAUTH2_ERROR_HAS_APPLE_ACCOUNT,
  OAUTH2_ERROR_HAS_GOOGLE_ACCOUNT,
  OAUTH2_ERROR_HAS_LOCAL_ACCOUNT,
} from 'constants/auth'
import { IAuthClient, IUser, ResponseErrorData } from 'models'
import { appleLoginIdToken, loginClient } from 'services/Auth'
import Button from 'components/Button'
import { updateUser } from 'services/Users'

const appleRedirectUri = OAUTH2_APPLE_REDIRECT_URI
const appleClietnId = OAUTH2_APPLE_CLIENT_ID

interface IAppleButtonProps {
  setLoginBtnHighlighted: Dispatch<SetStateAction<boolean>>
  setGoogleLoginBtnHighlighted: Dispatch<SetStateAction<boolean>>
  setupUser: () => void
  appleLoginBtnHighlighted: boolean
}

const AppleButton: FC<IAppleButtonProps> = ({
  setLoginBtnHighlighted,
  setGoogleLoginBtnHighlighted,
  setupUser,
  appleLoginBtnHighlighted,
}) => {
  const appleResponse = (response: AppleAuthResponse) => {
    loginClient()
      .then((data: IAuthClient) => {
        setCookie(ACCESS_CLIENT_TOKEN_KEY, data.access_token)
        verifyAppleIdToken(response.authorization.id_token, response.user?.name)
      })
      .catch((error: AxiosError) => {
        console.log(error)
      })
  }

  const verifyAppleIdToken = (idToken: string, user?: Partial<IUser>) => {
    appleLoginIdToken({ idToken })
      .then(() => {
        if (user) {
          updateUser({ firstName: user.firstName, lastName: user.lastName }).then(() => {
            setupUser()
          })
        } else {
          setupUser()
        }
      })
      .catch((responseError: AxiosError) => {
        const { response } = responseError
        const { error, error_description } = responseError.response?.data as ResponseErrorData
        if (
          response?.status === 400 &&
          (error === OAUTH2_ERROR_HAS_LOCAL_ACCOUNT ||
            error === OAUTH2_ERROR_HAS_GOOGLE_ACCOUNT ||
            error === OAUTH2_ERROR_HAS_APPLE_ACCOUNT)
        ) {
          alert(error_description + 'Please, try the highlighted login with the same account.')
          error.includes('DIFFERENT') && setLoginBtnHighlighted(true)
          error.includes('GOOGLE') && setGoogleLoginBtnHighlighted(true)
        }
      })
  }

  return (
    <AppleSignin
      authOptions={{
        clientId: appleClietnId,
        scope: 'email name',
        redirectURI: window.location.href.includes('members')
          ? appleRedirectUri
          : appleRedirectUri.replace('members.', ''),
        usePopup: true,
      }}
      onSuccess={(response) => appleResponse(response)}
      render={(props) => (
        <Button
          {...props}
          className={cx(
            'btn-secondary btn-full auth-section-btn-apple',
            appleLoginBtnHighlighted && 'btn-highlight',
          )}
          text='Sign in with Apple ID'
          style={{ height: '70px', maxHeight: '70px', fontSize: 'var(--font-size-s)' }}
        >
          <FontAwesomeIcon icon={faApple} size='2x' />
        </Button>
      )}
    />
  )
}

export default AppleButton
