import { FC, ReactElement } from 'react'

import cx from 'classnames'

import './TabPanel.scss'

interface ITabPanelProps {
  className?: string
  isDisabled?: boolean
  title: string
  children: ReactElement
}

const TabPanel: FC<ITabPanelProps> = ({ className, children }) => {
  return <div className={cx('tab-panel', className)}>{children}</div>
}

export default TabPanel
