import { ChangeEvent, FC } from 'react'

import cx from 'classnames'

import './Radio.scss'

interface IRadioProps {
  className?: string
  direction?: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  options: IRadioOption[]
}

interface IRadioOption {
  disabled?: boolean
  label: string
  value: string
}

const Radio: FC<IRadioProps> = ({ className, options, onChange, direction = 'row' }) => {
  return (
    <div
      className={cx('radio', className)}
      style={{ flexDirection: direction as 'row' | 'column' }}
    >
      {options.map(({ label, value, disabled = false }: IRadioOption, index) => {
        const shortenedOptionLabel = label.replace(/\s+/g, '')
        const optionId = `radio-option-${shortenedOptionLabel}`
        return (
          <div key={optionId} className='radio-option'>
            <input
              className='radio-option-input'
              defaultChecked={index === 0}
              disabled={disabled}
              id={optionId}
              key={optionId}
              name='radio-btn'
              onChange={onChange}
              type='radio'
              value={value}
            />
            <label
              className={cx('radio-option-label', disabled && 'radio-option-label-disabled')}
              htmlFor={optionId}
            >
              <span className='radio-option-label-text'>{label}</span>
            </label>
          </div>
        )
      })}
    </div>
  )
}

export default Radio
