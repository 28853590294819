import './Footer.scss'

const Footer = () => {
  return (
    <div className='footer'>
      <div className='footer-actions'>
        <a
          className='footer-actions-terms'
          href='/privacy'
          target='_self'
          rel='noopener noreferrer'
        >
          Terms and Conditions
        </a>
        <a
          className='footer-actions-privacy'
          href='/privacy'
          target='_self'
          rel='noopener noreferrer'
        >
          Privacy
        </a>
        <a className='footer-actions-faq' target='_self' rel='noopener noreferrer'>
          FAQ
        </a>
        <a className='footer-actions-help' target='_self' rel='noopener noreferrer'>
          Help Center
        </a>
      </div>
      <div className='footer-text'>
        <p>© Copyrights Nistruct 2020. All rights reserved. Patents pending.</p>
      </div>
    </div>
  )
}

export default Footer
