import { FC, ReactNode, useState } from 'react'

import cx from 'classnames'

import './Tooltip.scss'

interface ITooltipProps {
  children: ReactNode
  className?: string
  content: string
  showDelay?: number
  hideDelay?: number
  direction: string
}

const Tooltip: FC<ITooltipProps> = ({
  className,
  content,
  showDelay = 300,
  hideDelay = 600,
  direction,
  children,
}) => {
  let showTimeout: ReturnType<typeof setTimeout>
  let hideTimeout: ReturnType<typeof setTimeout>
  const [active, setActive] = useState(false)

  const showTip = () => {
    showTimeout = setTimeout(() => {
      setActive(true)
    }, showDelay)
  }

  const hideTip = () => {
    clearTimeout(showTimeout)
    hideTimeout = setTimeout(() => {
      setActive(false)
      clearTimeout(hideTimeout)
    }, hideDelay)
  }

  return (
    <div className={cx('tooltip', className)} onMouseEnter={showTip} onMouseLeave={hideTip}>
      {children}
      {active && <div className={`tooltip-box ${direction || 'top'}`}>{content}</div>}
    </div>
  )
}

export default Tooltip
