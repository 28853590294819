import { FC, useEffect, useState } from 'react'

import { AxiosError } from 'axios'
import cx from 'classnames'

import Table from 'components/Table'

import { getInvoicesList } from 'services/Invoices'
import { ColumnDefinitionType, IInvoice } from 'models'
import { NO_PAYMENT_METHODS_PLACEHOLDER } from 'constants/invoices'

import './Invoices.scss'

interface IInvoicesProps {
  className?: string
  teamId: string
}

const columns: ColumnDefinitionType<IInvoice, keyof IInvoice>[] = [
  {
    key: 'id',
    header: 'ID',
    type: 'link',
  },
  {
    key: 'issueDate',
    header: 'Issued',
    type: 'date',
  },
  {
    key: 'amountDue',
    header: 'Due amount',
    type: 'price',
  },
  {
    key: 'amountPaid',
    header: 'Paid amount',
    type: 'price',
  },
  {
    key: 'paid',
    header: 'Status',
    type: 'boolean',
    width: 70,
  },
  {
    key: 'paidAtDate',
    header: 'Payment date',
    type: 'date',
  },
  {
    key: 'periodStartDate',
    header: 'Started',
    type: 'date',
  },
  {
    key: 'periodEndDate',
    header: 'Ending',
    type: 'date',
  },
]

const Invoices: FC<IInvoicesProps> = ({ className, teamId }) => {
  const [invoices, setInvoices] = useState<IInvoice[]>([])

  useEffect(() => {
    getInvoicesList({ teamId })
      .then((invoicesResponse) => {
        setInvoices(invoicesResponse)
      })
      .catch((error: AxiosError) => {
        console.log(error)
      })
  }, [teamId])

  return (
    <div className={cx('invoices', className)}>
      <div className='invoices-content'>
        {invoices.length ? (
          <Table data={invoices} columns={columns} />
        ) : (
          <p className='invoices-content-placeholder'>{NO_PAYMENT_METHODS_PLACEHOLDER}</p>
        )}
      </div>
    </div>
  )
}

export default Invoices
