import { AxiosResponse } from 'axios'

import { Api } from 'api'
import { IGetAllTeamsResponse, ITeam } from 'models'
import { GET_ALL_TEAMS_API_URL, GET_TEAM_API_URL, TEAM_ROLES } from 'constants/teams'
import { getCookie } from 'typescript-cookie'
import { ACCESS_USER_TOKEN_KEY } from 'constants/auth'
import { parseJwt } from 'helpers'

interface GetTeamProps {
  teamId: string
}

const getTeamUrl = GET_TEAM_API_URL
const getAllTeamsUrl = GET_ALL_TEAMS_API_URL

export const getAllTeams = () => {
  return Api.get(getAllTeamsUrl).then((response: AxiosResponse<IGetAllTeamsResponse>) => {
    return response.data.teams
      .filter((team) => {
        const access_token = getCookie(ACCESS_USER_TOKEN_KEY)

        if (!access_token) {
          return
        }

        const team_roles = parseJwt(access_token).team_roles as {
          [key: string]: { role: string; subscription: boolean }
        }

        if (team_roles[team.id] && team_roles[team.id]['role'] === TEAM_ROLES.TEAM_OWNER)
          return team
      })
      .sort((team1, team2) => {
        if (team1.name < team2.name) {
          return -1
        }

        if (team1.name > team2.name) {
          return 1
        }

        return 0
      })
  })
}

export const getTeam = (data: GetTeamProps) => {
  return Api.get(getTeamUrl, { params: { teamId: data.teamId } }).then(
    (response: AxiosResponse<ITeam>) => {
      return response.data
    },
  )
}
