export const PAYMENT_METHODS_TITLE = 'Payment Methods'

export const DEFAULT_PAYMENT_METHOD_LABEL = 'Default payment method'
export const CARD_SELECTION_LABEL = 'Choose another card:'
export const GET_VAT_NUMBER_URL = 'api/v1/FUTSAL/payments/vat-number'
export const UPDATE_VAT_NUMBER_URL = 'api/v1/FUTSAL/payments/vat-number'

export const GET_COMPANY_URL = 'api/v1/FUTSAL/companies'
export const CREATE_COMPANY_URL = 'api/v1/FUTSAL/companies'
export const UPDATE_COMPANY_URL = 'api/v1/FUTSAL/companies'
export const DELETE_COMPANY_URL = 'api/v1/FUTSAL/companies'
