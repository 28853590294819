import { AxiosResponse } from 'axios'
import { Api } from 'api'
import { ITeamSubscriptionData, TSubscriptionCancelPeriod, TSubscriptionStart } from 'models'
import {
  CANCEL_SUBSCRIPTION_URL,
  GET_TEAM_SUBSCRIPTION_DATA_URL,
  START_SUBSCRIPTION_URL,
  TRIAL_AVAILABILITY_URL,
} from 'constants/subscriptions'

interface GetTeamSubscriptionDataProps {
  teamId: string
}

interface StartSubscriptionProps {
  teamId: string
  subscriptionType: TSubscriptionStart
}

interface CancelSubscriptionProps {
  subscriptionCancelPeriod: TSubscriptionCancelPeriod
  teamId: string
}

interface TrialAvailabilityProps {
  teamId: string
}

const getTeamSubscriptionDataUrl = GET_TEAM_SUBSCRIPTION_DATA_URL
const startSubscriptionUrl = START_SUBSCRIPTION_URL
const cancelSubscriptionUrl = CANCEL_SUBSCRIPTION_URL
const trialAvailableUrl = TRIAL_AVAILABILITY_URL

export const getLastTeamSubscription = (data: GetTeamSubscriptionDataProps) => {
  const params = {
    size: 1,
  }
  return Api.get(`${getTeamSubscriptionDataUrl}/${data.teamId}`, { params }).then(
    (response: AxiosResponse<{ subscriptions: ITeamSubscriptionData[] }>) => {
      return response.data.subscriptions[0]
    },
  )
}

export const startSubscription = (data: StartSubscriptionProps) => {
  return Api.post(startSubscriptionUrl, data).then((response: AxiosResponse) => {
    return response.data
  })
}

export const cancelSubscription = (data: CancelSubscriptionProps) => {
  return Api.post(cancelSubscriptionUrl, data).then((response: AxiosResponse) => {
    return response.data
  })
}

export const trialAvailable = (data: TrialAvailabilityProps) => {
  return Api.get(`${trialAvailableUrl}/${data.teamId}`).then((response: AxiosResponse) => {
    return response.data
  })
}
