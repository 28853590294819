import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { getCookie, removeCookie } from 'typescript-cookie'
import { toast } from 'react-toastify'

import Logo from 'components/Logo'
import AuthSection from 'components/AuthSection'
import background from 'assets/images/background.webp'
import { CURRENT_VERSION, CURRENT_VERSION_NUMBER, WELCOME_HEADLINE } from 'constants/general'
import { REDIRECT_LOGOUT_KEY, REDIRECT_LOGOUT_MESSAGE } from 'constants/auth'
import { getIsAuthenticated } from 'services/Auth'
import { ROUTES } from 'constants/routes'

import './Welcome.scss'

const Welcome = () => {
  const navigate = useNavigate()

  useEffect(() => {
    if (getIsAuthenticated()) {
      navigate(ROUTES.PORTAL.PATH)
    }

    if (!getIsAuthenticated()) {
      if (getCookie(REDIRECT_LOGOUT_KEY)) {
        removeCookie(REDIRECT_LOGOUT_KEY)
        toast(REDIRECT_LOGOUT_MESSAGE)
      }
    }
  }, [])

  return (
    <>
      <div className='welcome'>
        <div className='welcome-background' style={{ backgroundImage: `url(${background})` }}>
          <div className='welcome-content'>
            <Logo className='welcome-logo' link={ROUTES.WEBSITE.PATH} />
            <h1 className='welcome-title'>{WELCOME_HEADLINE}</h1>
            <AuthSection />
          </div>
        </div>
      </div>
      <span className='welcome-app-version'>
        {CURRENT_VERSION.toUpperCase()}: {CURRENT_VERSION_NUMBER}
      </span>
    </>
  )
}

export default Welcome
