import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import cx from 'classnames'

import Avatar from 'components/Avatar'
import Button from 'components/Button'
import { TEAM_FIRST_COLOR_PLACEHOLDER, TEAM_SECOND_COLOR_PLACEHOLDER } from 'constants/teams'
import { ROUTES } from 'constants/routes'
import { ITeamBase, EMembershipStatus } from 'models'
import { convertARGBToHex } from 'helpers'

import './Team.scss'

interface ITeamProps {
  className?: string
  index: number
  team: ITeamBase
}

const Team: FC<ITeamProps> = ({ className = '', index, team }) => {
  const navigate = useNavigate()
  const [isHovered, setIsHovered] = useState(false)

  let text = 'No subscription'
  let txtColor = '#EB0000'
  let btnText = 'Choose a plan'
  switch (team.membershipStatus) {
    case EMembershipStatus.trial:
      text = 'Trial active'
      txtColor = '#87AD1A'
      btnText = 'Team Plan'
      break
    case EMembershipStatus.active:
      text = 'Subscripton active'
      txtColor = '#87AD1A'
      btnText = 'Team Plan'
      break
    default:
      text = 'No subscription'
  }

  return (
    <li
      className={cx('team', className)}
      id={`team-${index}`}
      style={index % 2 === 0 ? { backgroundColor: '#FAFAFA' } : undefined}
    >
      <div className='team-avatar' id={`team-${index}-avatar`}>
        <Avatar
          firstColor={convertARGBToHex(team.firstColor || TEAM_FIRST_COLOR_PLACEHOLDER)}
          image={team.logoUrl}
          placeholder='color'
          secondColor={convertARGBToHex(team.secondColor || TEAM_SECOND_COLOR_PLACEHOLDER)}
          width='40px'
          height='40px'
        />
      </div>
      <div className='team-name' id={`team-${index}-name`}>
        <span>{team.name}</span>
      </div>
      <div className='team-status' style={{ color: `${txtColor}` }}>
        <p>{text}</p>
      </div>
      <Button
        className='btn-primary btn-field team-plan'
        text={btnText}
        onClick={() =>
          navigate(`${ROUTES.PORTAL.PATH}/${ROUTES.FINANCIALS.PATH}?teamId=${team.id}`)
        }
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onTouchStart={() => setIsHovered(true)}
        onTouchEnd={() => setIsHovered(false)}
        id={`team-${index}-button`}
        style={{
          width: '157px',
          height: '40px',
          padding: '15px 32px 15px 32px',
          textAlign: 'center',
          border: text === 'No subscription' ? '' : isHovered ? '' : '2px solid #4B4B4B',
          backgroundColor: text === 'No subscription' ? '#000000' : '#FFFFFF',
          color: text === 'No subscription' ? '#FFFFFF' : '#000000',
          boxShadow: isHovered
            ? '0px 4px 15px 0px rgba(93, 95, 239, 0.6), 0px -4px 15px 0px rgba(235, 0, 0, 0.3)'
            : '',
        }}
      />
    </li>
  )
}

export default Team
