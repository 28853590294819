import { FC } from 'react'

import cx from 'classnames'

import './TabItem.scss'

interface ITabItemProps {
  className?: string
  isDisabled: boolean
  index: number
  setSelectedTab: (index: number) => void
  title: string
  isActive: boolean
}

const TabItem: FC<ITabItemProps> = ({
  className,
  isDisabled,
  index,
  isActive,
  setSelectedTab,
  title,
}) => {
  return (
    <li
      className={cx(
        'tab-item',
        isActive && 'tab-item-active',
        isDisabled && 'tab-item-disabled',
        className,
      )}
      onClick={() => setSelectedTab(index)}
    >
      <button disabled={isDisabled} style={{height: 'fit-content', width: 'fit-content', padding: '10px 20px 10px 20px'}}>{title}</button>
    </li>
  )
}

export default TabItem