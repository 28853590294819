import { AxiosResponse } from 'axios'
import { Api } from 'api'
import {
  CREATE_COMPANY_URL,
  DELETE_COMPANY_URL,
  GET_COMPANY_URL,
  GET_VAT_NUMBER_URL,
  UPDATE_COMPANY_URL,
  UPDATE_VAT_NUMBER_URL,
} from 'constants/payments'
import { Company, IVatNumberResponse } from 'models'

interface GetVatNumberProps {
  teamId: string
}

interface UpdateVatNumberUrlProps {
  teamId: string
  vatFormat: string
  vatNumber: string
}

interface GetCompanyInfoProps {
  companyId: string
}

interface CreateCompanyInfoProps {
  userId: string
  email: string
  address: string
  name: string
  vatNumber: string
}

interface UpdateCompanyInfoProps {
  email: string
  address: string
  name: string
  vatNumber: string
}

const getVatNumberUrl = GET_VAT_NUMBER_URL
const updateVatNumberUrl = UPDATE_VAT_NUMBER_URL
const getCompanyUrl = GET_COMPANY_URL
const createCompanyUrl = CREATE_COMPANY_URL
const updateCompanyUrl = UPDATE_COMPANY_URL
const deleteCompanyUrl = DELETE_COMPANY_URL

export const getVatNumber = (data: GetVatNumberProps) => {
  return Api.get(`${getVatNumberUrl}/${data.teamId}`).then(
    (response: AxiosResponse<IVatNumberResponse>) => {
      return response.data
    },
  )
}

export const updateVatNumber = (data: UpdateVatNumberUrlProps) => {
  return Api.post(updateVatNumberUrl, data).then((response: AxiosResponse) => {
    return response.data
  })
}

export const getCompanyInfo = (data: GetCompanyInfoProps) => {
  console.log(data)
  return Api.get(`${getCompanyUrl}/${data.companyId}`).then((response: AxiosResponse<Company>) => {
    return response.data
  })
}

export const createCompanyInfo = (data: CreateCompanyInfoProps) => {
  console.log(data)
  return Api.post(createCompanyUrl, data).then((response: AxiosResponse) => {
    return response.data
  })
}

export const updateCompanyInfo = (data: UpdateCompanyInfoProps, id: string) => {
  return Api.put(`${updateCompanyUrl}/${id}`, data).then((response: AxiosResponse) => {
    return response.data
  })
}

export const deleteCompanyInfo = (data: GetCompanyInfoProps) => {
  return Api.delete(`${deleteCompanyUrl}/${data.companyId}`)
}
