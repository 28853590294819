import { Dispatch, FC, SetStateAction, useEffect, /* useEffect, */ useState } from 'react'

import { AxiosError } from 'axios'
import cx from 'classnames'

import Button from 'components/Button'
import Radio from 'components/Radio'
import {
  AT_THE_END_CANCELLATION_LABEL,
  CANCELLATION_ACTION_BUTTON_TEXT,
  IMMEDIATELY_CANCELLATION_LABEL,
  MONTHLY_SUBSCRIPTION_LABEL,
  YEARLY_SUBSCRIPTION_LABEL,
  BENEFITS,
} from 'constants/subscriptions'
import {
  EMembershipStatus,
  ESubscriptionCancelPeriod,
  ESubscriptionStart,
  ESubscriptionType,
  initialTeamSubscription,
  ITeamSubscriptionData,
  TSubscriptionCancelPeriod,
  TSubscriptionStart,
} from 'models'
import {
  cancelSubscription /* createCheckoutPage */,
  startSubscription,
  trialAvailable,
} from 'services/Subscriptions'
import { getTimeLeftInDays } from 'helpers'

import './Subscription.scss'
import Pill from 'components/Pill'

interface ISubscriptionSelectProps {
  className?: string
  teamId: string
  subscription: ITeamSubscriptionData
  setSubscription: Dispatch<SetStateAction<ITeamSubscriptionData>>
}

const Subscription: FC<ISubscriptionSelectProps> = ({
  className,
  teamId,
  subscription = initialTeamSubscription,
  setSubscription,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isTrialAvailable, setIsTrialAvailable] = useState<boolean>(false)

  useEffect(() => {
    trialAvailable({ teamId }).then((trialAvailable) => {
      const { isTrialAvailable } = trialAvailable
      setIsTrialAvailable(isTrialAvailable)
    })
  }, [])

  const subscriptionPlans = [
    {
      label: MONTHLY_SUBSCRIPTION_LABEL,
      value: ESubscriptionStart.MONTHLY,
    },
    {
      label: YEARLY_SUBSCRIPTION_LABEL,
      value: ESubscriptionStart.YEARLY,
    },
  ]
  const subscriptionCancelPeriods = [
    {
      label: IMMEDIATELY_CANCELLATION_LABEL,
      value: ESubscriptionCancelPeriod.cancelImmediately,
    },
    {
      label: AT_THE_END_CANCELLATION_LABEL,
      value: ESubscriptionCancelPeriod.cancelAtTheEndOfTheCurrentBillingPeriod,
    },
  ]

  const [subscriptionCancelPeriod, setSubscriptionCancelPeriod] =
    useState<TSubscriptionCancelPeriod>(subscriptionCancelPeriods[0].value)

  const handleCancellation = () => {
    setIsLoading(true)
    const isConfirmed = window.confirm('Are you sure you want to cancel the subscription?')
    if (isConfirmed) {
      cancelSubscription({ subscriptionCancelPeriod, teamId }).catch((error: AxiosError) => {
        console.log(error)
      })
    }
    setIsLoading(false)
  }

  const handleSubscription = (subscriptionType: TSubscriptionStart): void => {
    setIsLoading(true)
    const isConfirmed = window.confirm('Are you sure you want to start the subscription?')
    if (isConfirmed) {
      startSubscription({ teamId, subscriptionType })
        .then((subscription: ITeamSubscriptionData) => setSubscription(subscription))
        .catch((error: AxiosError) => {
          console.log(error)
          alert(
            'Please set your address and VAT number or company info in order to start subscription',
          )
        })
    }
    setIsLoading(false)
  }

  const plan = (expiresIn: string, btnText: string, plan = MONTHLY_SUBSCRIPTION_LABEL) => {
    return (
      <div className='subscription-box-content'>
        <div className='subscription-box-content-title'>
          <p>{plan + ' Plan'}</p>
        </div>
        <div className='subscription-box-content-plan'>
          <p className='subscription-box-content-plan-title'>Subscription Plan</p>
          <p className='subscription-box-content-plan-text'>{expiresIn}</p>
          <div className={`subscription-box-content-plan-actions-${plan}`}>
            {(btnText !== 'Extend your Plan' ||
              (subscription.membershipStatus !== EMembershipStatus.active &&
                subscription.cancelled)) && (
              <Button
                text={btnText}
                disabled={isLoading}
                onClick={() =>
                  handleSubscription(
                    subscriptionPlans[0].label === plan
                      ? subscriptionPlans[0].value
                      : subscriptionPlans[1].value,
                  )
                }
                className='subscription-box-content-plan-actions-extend'
                style={{
                  padding: '15px 32px 15px 32px',
                  height: 'fit-content',
                  background: '#000000',
                  color: '#FFFFFF',
                  marginTop: '2%',
                }}
              />
            )}
            {subscription.membershipStatus === EMembershipStatus.active &&
              !subscription.cancelled && (
                <div className='subscription-box-content-plan-actions-cancel'>
                  <Radio
                    options={subscriptionCancelPeriods}
                    onChange={(event) =>
                      setSubscriptionCancelPeriod(event.target.value as TSubscriptionCancelPeriod)
                    }
                  />
                  <Button
                    text={CANCELLATION_ACTION_BUTTON_TEXT}
                    disabled={isLoading}
                    onClick={() => handleCancellation()}
                    className='subscription-box-content-plan-actions-cancel-btn'
                    style={{
                      padding: '15px 32px 15px 32px',
                      height: 'fit-content',
                      background: '#000000',
                      color: '#FFFFFF',
                      marginTop: '2%',
                    }}
                  />
                </div>
              )}
          </div>
        </div>
        <div className='subscription-box-content-benefits'>
          <div className='subscription-box-content-benefits-title'>
            <span>Benefits</span>
          </div>
          <ul className='subscription-box-content-benefits-list'>
            {BENEFITS[plan].map((value, index) => {
              return (
                <li
                  key={subscription.subscriptionType + '-benefit-' + index}
                  className='subscription-box-content-benefits-text'
                >
                  {value}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    )
  }

  const subscribedInfo = () => {
    return (
      <Pill className='subscription-box'>
        {plan(
          'Expires in ' + getTimeLeftInDays(subscription.endDate).toString() + ' days',
          'Extend your Plan',
          subscription.subscriptionType === ESubscriptionType.trial ||
            subscription.subscriptionType === ESubscriptionType.monthly
            ? MONTHLY_SUBSCRIPTION_LABEL
            : YEARLY_SUBSCRIPTION_LABEL,
        )}
      </Pill>
    )
  }

  const unsubscribedInfo = () => {
    return (
      <>
        <Pill className='subscription-box'>
          {plan(
            'No subscription',
            ((subscription?.membershipStatus === EMembershipStatus.inactive &&
              !subscription?.cancelled) ??
              subscription.subscriptionType === ESubscriptionType.yearly) &&
              subscription.subscriptionType !== ESubscriptionType.trial
              ? 'Switch to other plan'
              : 'Extend your plan',
            YEARLY_SUBSCRIPTION_LABEL,
          )}
        </Pill>
        <Pill
          className='subscription-box'
          style={{ marginTop: '20px', padding: '22px 20px 22px 20px' }}
        >
          <Pill className='subscription-box-inside'>
            {plan(
              'No subscription',
              ((subscription?.membershipStatus === EMembershipStatus.inactive &&
                !subscription?.cancelled) ??
                subscription.subscriptionType === ESubscriptionType.monthly) &&
                subscription.subscriptionType !== ESubscriptionType.trial
                ? 'Switch to other plan'
                : 'Extend your plan',
              MONTHLY_SUBSCRIPTION_LABEL,
            )}
          </Pill>
        </Pill>
      </>
    )
  }

  const trialAvailableInfo = () => {
    return (
      <Pill className='subscription-box'>
        <div className='subscription-box-content'>
          <div className='subscription-box-content-title'>
            <p>Trial</p>
          </div>
          <div className='subscription-box-content-plan'>
            <p className='subscription-box-content-plan-title'>Subscription Plan</p>
            <p className='subscription-box-content-plan-text'>No subscription</p>
            <div className={`subscription-box-content-plan-actions-${plan}`}>
              <Button
                text='Start trial'
                disabled={isLoading}
                onClick={() => handleSubscription('TRIAL')}
                className='subscription-box-content-plan-actions-extend'
                style={{
                  padding: '15px 32px 15px 32px',
                  height: 'fit-content',
                  background: '#000000',
                  color: '#FFFFFF',
                  marginTop: '2%',
                }}
              />
            </div>
          </div>
          <div className='subscription-box-content-benefits'>
            <div className='subscription-box-content-benefits-title'>
              <span>Benefits</span>
            </div>
            <ul className='subscription-box-content-benefits-list'>
              <li key='trial-benefit-0' className='subscription-box-content-benefits-text'>
                Try premium use of our app 30 days for free
              </li>
            </ul>
          </div>
        </div>
      </Pill>
    )
  }

  return (
    <div className={cx('subscription', className)}>
      {subscription?.id && subscription?.membershipStatus !== EMembershipStatus.inactive
        ? subscribedInfo()
        : isTrialAvailable
        ? trialAvailableInfo()
        : unsubscribedInfo()}
    </div>
  )
}

export default Subscription
