export const SUBSCRIPTION = 'Subscription'

export const GET_TEAM_SUBSCRIPTION_DATA_URL = 'api/v1/FUTSAL/subscriptions/list'
export const START_SUBSCRIPTION_URL = 'api/v1/FUTSAL/subscriptions/start'
export const CANCEL_SUBSCRIPTION_URL = 'api/v1/FUTSAL/subscriptions/cancel'
export const TRIAL_AVAILABILITY_URL = 'api/v1/FUTSAL/subscriptions/trial-available'

export const SUBSCRIBE_ACTION_BUTTON_TEXT = 'Subscribe'
export const CANCELLATION_ACTION_BUTTON_TEXT = 'Cancel subscription'
export const MONTHLY_SUBSCRIPTION_LABEL = 'Monthly'
export const YEARLY_SUBSCRIPTION_LABEL = 'Yearly'
export const IMMEDIATELY_CANCELLATION_LABEL = 'Immediately'
export const AT_THE_END_CANCELLATION_LABEL = 'At the end of the billing period'

export const TRIAL_AVAILABLE_TEXT = 'Try 30 days for free'
export const TRIAL_UNAVAILABLE_TEXT = 'Subscribe'
export const SUBSCRIBE_ACTION_TEXT = 'Choose your plan:'
export const SUBSCRIPTION_ENDING_TEXT = ' This subscription ends in '
export const TRIAL_ENDING_TEXT = 'Trial period ends in '
export const CANCEL_AT_THE_END_TEXT = 'It will be terminated at the end of the billing period.'
export const CANCEL_ANYTIME_TEXT = 'You can terminate it anytime:'

export const BENEFITS = {
  [YEARLY_SUBSCRIPTION_LABEL as string]: ['Save up to 20% on your monthly payment'],
  [MONTHLY_SUBSCRIPTION_LABEL as string]: ['Cancel at any time', 'Pay as you go'],
}
