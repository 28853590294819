import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import Redirect from 'components/Redirect'
import { loginUser } from 'services/Auth'
import { useAuthSetup } from 'hooks/useAuthSetup'

const Authorized = () => {
  const [searchParams] = useSearchParams()
  const codeSearchParam = searchParams.get('code') as string
  const [setupUser] = useAuthSetup()

  useEffect(() => {
    if (codeSearchParam) {
      onAuthCode(codeSearchParam)
    }
  }, [searchParams])

  const onAuthCode = (code: string) => {
    loginUser({ code }).then(setupUser)
  }

  return <Redirect />
}

export default Authorized
