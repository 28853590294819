import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { AxiosError } from 'axios'

import Invoices from 'components/Invoices'
import Subscription from 'components/Subscription'
import TabPanel from 'components/Tabs/TabPanel'
import Tabs from 'components/Tabs'
import TeamSelect from 'components/TeamSelect'
import VatNumber from 'components/VatNumber'
import { getTeam } from 'services/Teams'
import {
  initialTeam,
  ITeam,
  ITeamSubscriptionData,
  initialTeamSubscription,
  EMembershipStatus,
  Company,
  initialUser,
  IUser,
} from 'models'
import { getLastTeamSubscription } from 'services/Subscriptions'

import './Financials.scss'
import Pill from 'components/Pill'
import CompanyDetails from 'components/CompanyDetails'
import { getCompanyInfo } from 'services/Payments'
import { getLoggedInUser } from 'services/Auth'

const Financials = () => {
  const [team, setTeam] = useState<ITeam>(initialTeam)
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0)
  const [subscription, setSubscription] = useState<ITeamSubscriptionData>(initialTeamSubscription)
  const [searchParams] = useSearchParams()
  const teamIdSearchParam = searchParams.get('teamId') as string
  const [company, setCompany] = useState<Company | null>(null)
  const [user, setUser] = useState<IUser>(initialUser)

  useEffect(() => {
    getLoggedInUser()
      .then((user: IUser) => {
        setUser(user)
        getCompanyInfo({ companyId: user.id })
          .then((company) => {
            setCompany(company)
          })
          .catch((err) => {
            console.log(err)
          })
      })
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    if (teamIdSearchParam) {
      initializeFinancials()
    }
  }, [searchParams])

  const initializeFinancials = () => {
    getTeamDetails()
    getTeamSubscription()
    setActiveTabIndex(0)
  }

  const getTeamDetails = () => {
    getTeam({ teamId: teamIdSearchParam })
      .then((teamResponse) => {
        setTeam(teamResponse)
      })
      .catch((error: AxiosError) => {
        setTeam(initialTeam)
        console.log(error)
      })
  }

  const getTeamSubscription = () => {
    getLastTeamSubscription({ teamId: teamIdSearchParam })
      .then((subscriptionResponse) => {
        setSubscription(subscriptionResponse ?? initialTeamSubscription)
      })
      .catch((error: AxiosError) => {
        console.log(error)
        setSubscription(initialTeamSubscription)
      })
  }

  return (
    <div className='financials'>
      <div className='financials-header'>
        <Pill className='financials-header-box'>
          <TeamSelect selectedTeam={team} />
        </Pill>
        <div className='financials-header-subscription'>
          <div className='financials-header-subscription-plan'>
            <Pill className='financials-header-subscription-plan-box'>
              <div className='financials-header-subscription-plan-box-title'>
                <p className='financials-header-subscription-plan-box-title-text'>
                  Subscription plan
                </p>
              </div>
              <div
                className='financials-header-subscription-plan-box-text'
                style={{ color: 'Supporting/SG500 - Active Green' }}
              >
                <p
                  style={
                    subscription.membershipStatus === EMembershipStatus.inactive
                      ? { color: '#EB0000' }
                      : { color: '#87AD1A' }
                  }
                >
                  {subscription.membershipStatus === EMembershipStatus.inactive
                    ? 'No subscription'
                    : subscription.subscriptionType}
                </p>
              </div>
            </Pill>
          </div>
        </div>
      </div>

      <div className='financials-body'>
        {company === null ? (
          <Tabs className='financials-body-tabs' active={activeTabIndex}>
            <TabPanel className='financials-body-tabs-tab-panel' title='Current Plan'>
              <Subscription
                teamId={teamIdSearchParam}
                subscription={subscription}
                setSubscription={setSubscription}
              />
            </TabPanel>
            <TabPanel className='financials-body-tabs-tab-panel' title='Company Details'>
              <CompanyDetails company={company} setCompany={setCompany} user={user} />
            </TabPanel>
            <TabPanel className='financials-body-tabs-tab-panel' title="User's address">
              <VatNumber user={user} setUser={setUser} />
            </TabPanel>
            <TabPanel className='financials-body-tabs-tab-panel' title='Invoices'>
              <Invoices teamId={teamIdSearchParam} />
            </TabPanel>
          </Tabs>
        ) : (
          <Tabs className='financials-body-tabs' active={activeTabIndex}>
            <TabPanel className='financials-body-tabs-tab-panel' title='Current Plan'>
              <Subscription
                teamId={teamIdSearchParam}
                subscription={subscription}
                setSubscription={setSubscription}
              />
            </TabPanel>
            <TabPanel className='financials-body-tabs-tab-panel' title='Company Details'>
              <CompanyDetails company={company} setCompany={setCompany} user={user} />
            </TabPanel>
            <TabPanel className='financials-body-tabs-tab-panel' title='Invoices'>
              <Invoices teamId={teamIdSearchParam} />
            </TabPanel>
          </Tabs>
        )}
      </div>
    </div>
  )
}

export default Financials
