import { Dispatch, FC, SetStateAction } from 'react'

import cx from 'classnames'
import { IUser } from 'models'

import './VatNumber.scss'
import UserAddress from 'components/UserAddress'

interface IVatNumberProps {
  className?: string
  user: IUser
  setUser: Dispatch<SetStateAction<IUser>>
}

const VatNumber: FC<IVatNumberProps> = ({ className, user, setUser }) => {
  return (
    <div className={cx('vat-number', className)}>
      <UserAddress user={user} setUser={setUser} />
    </div>
  )
}

export default VatNumber
