export const LOCAL_CLIENT_URL = 'http://localhost:3000/'
export const DEV_ENV_CLIENT_URL = 'https://members-dev.futsalstats.com/'
export const PROD_ENV_CLIENT_URL = 'https://members.futsalstats.com/'

export const CURRENT_VERSION = 'current version'
export const CURRENT_VERSION_NUMBER = '1.1.63.1' // format is: [number of the sprint].[sprint subversion].

export const WELCOME_HEADLINE =
  'The ultimate app for sports statistics to elevate your futsal team.'

export const APP_PREFIX = 'fs-'
