import cx from 'classnames'

import { ColumnDefinitionType, IInvoice } from 'models'
import Tooltip from 'components/Tooltip'
import { dateFormatter, priceFormatter } from 'helpers'

import './TableRows.scss'

type TableRowsProps<T, K extends keyof T> = {
  data: Array<T>
  columns: Array<ColumnDefinitionType<T, K>>
  className?: string
}

const TableRows = <T, K extends keyof T>({
  data,
  columns,
  className,
}: TableRowsProps<T, K>): JSX.Element => {
  const rows = data.map((row, rowIndex) => {
    return (
      <tr className='table-rows-row' key={`row-${rowIndex}`} style={rowIndex % 2 === 0 ? { background: '#FAFAFA' } : {}}>
        {columns.map((column, columnIndex) => {
          const value = row[column.key]
          let cell

          switch (column.type) {
            case 'boolean':
              cell = (
                <td className='table-rows-row-cell' key={`cell-${rowIndex}-${columnIndex}`}>
                  {value ? (
                    <span className='table-rows-row-cell-boolean-true'>{column.key as string}</span>
                  ) : (
                    <span className='table-rows-row-cell-boolean-false'>
                      Not {column.key as string}
                    </span>
                  )}
                </td>
              )
              break

            case 'price':
              cell = (
                <td className='table-rows-row-cell' key={`cell-${rowIndex}-${columnIndex}`}>
                  <span>{priceFormatter(value as number)}</span>
                </td>
              )
              break

            case 'date': {
              const date = new Date(value as string)
              const formattedDate = dateFormatter(date)
              cell = (
                <td className='table-rows-row-cell' key={`cell-${rowIndex}-${columnIndex}`}>
                  <span>{formattedDate}</span>
                </td>
              )
              break
            }

            case 'link': {
              const invoiceLink = (row as IInvoice).externalInvoicePage
              cell = (
                <td className='table-rows-row-cell table-rows-row-cell-clipped table-rows-row-cell-link'>
                  <Tooltip content={value as string} direction='right'>
                    <span>
                      <a href={invoiceLink} target='_blank' rel='noreferrer'>
                        #{value as string}
                      </a>
                    </span>
                  </Tooltip>
                </td>
              )
              break
            }

            default:
              cell = (
                <td className='table-rows-row-cell' key={`cell-${rowIndex}-${columnIndex}`}>
                  <span>{value as string}</span>
                </td>
              )
          }

          return cell
        })}
      </tr>
    )
  })

  return <tbody className={cx('table-rows', className)}>{rows}</tbody>
}

export default TableRows
