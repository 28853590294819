import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { AxiosError } from 'axios'
import { getCookie } from 'typescript-cookie'

import { GlobalContext } from 'contexts/global-context'
import { GlobalContextType, IUser } from 'models'
import { getLoggedInUser } from 'services/Auth'
import { ROUTES } from 'constants/routes'
import { REDIRECT_TEAM_ID_KEY } from 'constants/auth'

export const useAuthSetup = () => {
  const navigate = useNavigate()
  const { updateUser } = useContext(GlobalContext) as GlobalContextType

  const setup = () => {
    getLoggedInUser()
      .then((userResponse: IUser) => {
        updateUser(userResponse)
        redirectUser()
      })
      .catch((error: AxiosError) => {
        console.log(error)
      })
  }

  const redirectUser = () => {
    const teamIdToRedirect = getCookie(REDIRECT_TEAM_ID_KEY)
    if (teamIdToRedirect) {
      navigate(`${ROUTES.PORTAL.PATH}/${ROUTES.FINANCIALS.PATH}?teamId=${teamIdToRedirect}`)
    } else {
      navigate(ROUTES.PORTAL.PATH)
    }
  }

  return [setup]
}
