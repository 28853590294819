import cx from 'classnames'
import { ColumnDefinitionType } from 'models'

import './TableHeader.scss'

type TableHeaderProps<T, K extends keyof T> = {
  className?: string
  columns: Array<ColumnDefinitionType<T, K>>
}

const TableHeader = <T, K extends keyof T>({
  columns,
  className,
}: TableHeaderProps<T, K>): JSX.Element => {
  const headers = columns.map((column, index) => {
    const style = {
      width: column.width ?? 110,
    }

    return (
      <th key={`headCell-${index}`} style={style}>
        {column.header}
      </th>
    )
  })

  return (
    <thead className={cx('table-header', className)}>
      <tr>{headers}</tr>
    </thead>
  )
}

export default TableHeader
