import { FC, useEffect } from 'react'
import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom'

import { setCookie } from 'typescript-cookie'

import Navbar from 'components/Navbar'
import Redirect from 'components/Redirect'
import { getIsAuthenticated } from 'services/Auth'
import { ROUTES } from 'constants/routes'
import { REDIRECT_TEAM_ID_KEY } from 'constants/auth'
import { redirectUrlForAuthorizeServer } from 'helpers'

const Private: FC = () => {
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const emailParam = searchParams.get('email') as string
  const teamIdParam = searchParams.get('teamId') as string

  useEffect(() => {
    if (!getIsAuthenticated()) {
      if (teamIdParam) {
        setCookie(REDIRECT_TEAM_ID_KEY, teamIdParam)
      }
    }
  }, [searchParams])

  const determineNonAuthRedirect = () => {
    if (emailParam) {
      const emailParamEncoded = encodeURIComponent(emailParam)
      const redirectAuthUrl = redirectUrlForAuthorizeServer(emailParamEncoded)
      window.location.assign(redirectAuthUrl)

      return <Redirect />
    } else {
      return <Navigate to={ROUTES.WELCOME.PATH} state={{ from: pathname }} replace />
    }
  }

  return getIsAuthenticated() ? (
    <>
      <Navbar />
      <Outlet />
    </>
  ) : (
    determineNonAuthRedirect()
  )
}

export default Private
