import { Dispatch, FC, SetStateAction, useRef, useState } from 'react'

import './UserAddress.scss'
import { useBoolean } from 'hooks/useBoolean'
import Input from 'components/Input'
import Button from 'components/Button'
import { IUser } from 'models'
import { updateUser } from 'services/Users'

interface IUserAddressProps {
  user: IUser
  setUser: Dispatch<SetStateAction<IUser>>
}

const UserAddress: FC<IUserAddressProps> = ({ user, setUser }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editState, openEdit, closeEdit, toggleEdit] = useBoolean(false)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [isHovered, setIsHovered] = useState<boolean>(false)

  const onUpdateAddress = () => {
    if (inputRef.current) {
      const { value } = inputRef.current

      updateUser({ address: value })
        .then((response) => setUser(response.data))
        .catch((err) => console.log(err))
    }
  }

  return (
    <>
      {editState ? (
        <div className='user-address-edit'>
          <div className='user-address-edit-address'>
            <p className='user-address-edit-address-title'>User&apos;s address</p>
            <Input
              className='user-address-edit-address-input'
              id='user-address'
              ref={inputRef}
              placeholder={user.address || 'Update address'}
              type='text'
            />
          </div>
          <div className='user-address-edit-button'>
            <Button
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onTouchStart={() => setIsHovered(true)}
              onTouchEnd={() => setIsHovered(false)}
              text='Update'
              onClick={() => onUpdateAddress()}
              style={{
                padding: '15px 32px 15px 32px',
                border: isHovered ? '' : '2px solid #4B4B4B',
                color: '#4B4B4B',
                boxShadow: isHovered
                  ? '0px 4px 15px 0px #5D5FEF66, 0px -4px 15px 0px #EB000033'
                  : '',
              }}
            />
          </div>
        </div>
      ) : (
        <div className='user-address-content'>
          {user.address && <p className='user-address-content-address'>{user.address}</p>}
          {!user.address && <p className='user-address-content-no-address'>No address set.</p>}
          <div className='user-address-content-button'>
            <Button
              className='primary'
              text='Edit'
              onClick={() => toggleEdit()}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onTouchStart={() => setIsHovered(true)}
              onTouchEnd={() => setIsHovered(false)}
              style={{
                width: 'fit-content',
                padding: '15px 32px 15px 32px',
                border: isHovered ? '' : '2px solid #4B4B4B',
                color: '#4B4B4B',
                boxShadow: isHovered
                  ? '0px 4px 15px 0px #5D5FEF66, 0px -4px 15px 0px #EB000033'
                  : '',
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default UserAddress
