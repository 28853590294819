import { APP_PREFIX } from './general'

export const SOCIAL_LOGIN_PROVIDER = 'provider'
export enum SOCIAL_LOGIN_PROVIDERS {
  GOOGLE = 'GOOGLE',
  APPLE = 'APPLE',
  LOCAL = 'LOCAL',
}

export const ACCESS_CLIENT_TOKEN_KEY = `${APP_PREFIX}accessClientToken`
export const ACCESS_USER_TOKEN_KEY = `${APP_PREFIX}accessUserToken`
export const REFRESH_TOKEN_KEY = `${APP_PREFIX}refreshToken`

export const GRANT_TYPE_CLIENT_CREDENTIALS = 'client_credentials'
export const GRANT_TYPE_AUTHORIZATION_CODE = 'authorization_code'
export const GRANT_TYPE_REFRESH_TOKEN = 'refresh_token'

export const INVALID_TOKEN = 'invalid_token'
export const INVALID_ACCESS_TOKEN_DESCRIPTION = 'Access token expired'
export const INVALID_REFRESH_TOKEN_DESCRIPTION = 'Invalid refresh token (expired)'

export const HEADERS_CONTENT_TYPE_FORM_URLENCODED = 'application/json'

export const OAUTH2_GOOGLE_LOGIN_TOKEN_URL = 'https://oauth2.googleapis.com/token'
export const OAUTH2_GOOGLE_REDIRECT_URI = 'postmessage'
export const OAUTH2_GOOGLE_FLOW = 'auth-code'

export const OAUTH2_ERROR_HAS_LOCAL_ACCOUNT = 'ALREADY_REGISTERED_WITH_DIFFERENT_PROVIDER' // 'ALREADY_REGISTERED_WITH_LOCAL_PROVIDER'
export const OAUTH2_ERROR_HAS_GOOGLE_ACCOUNT = 'ALREADY_REGISTERED_WITH_GOOGLE_PROVIDER'
export const OAUTH2_ERROR_HAS_APPLE_ACCOUNT = 'ALREADY_REGISTERED_WITH_APPLE_PROVIDER'
export const OAUTH2_SOCIAL_LOGIN_ID_TOKEN_URL = 'api/v1/FUTSAL/oauth/single-sign-on'
export const OAUTH2_ID_TOKEN = 'idToken'

export const OAUTH2_APPLE_REDIRECT_URI = (process.env.REACT_APP_CLIENT_URL as string) + 'welcome'
export const OAUTH2_APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID as string
export const OAUTH2_APP_BUTTON_TEXT = 'Login'
export const OAUTH2_GOOGLE_BUTTON_TEXT = 'Sign in with Google'
export const OAUTH2_APPLE_BUTTON_TEXT = 'Sign in with Apple'

export const GET_USERS_API_URL = 'api/v1/FUTSAL/users'

export const LOGOUT_USER_API_URL = 'api/v1/FUTSAL/oauth/logout'

export const REDIRECT_LOGOUT_KEY = `${APP_PREFIX}redirectLogout`
export const REDIRECT_TEAM_ID_KEY = `${APP_PREFIX}teamIdToRedirect`
export const USER_KEY = `${APP_PREFIX}teamIdToRedirect`

export const LOGIN_MESSAGE = 'You are now logged in!'
export const REDIRECT_LOGOUT_MESSAGE = 'Your session expired. Please log in again.'
