import { useNavigate } from 'react-router-dom'
import { useContext, useRef, useState, useEffect } from 'react'

import cx from 'classnames'

import Avatar from 'components/Avatar'
import Button from 'components/Button'
import Logo from 'components/Logo'
import { clearSession, logoutUser } from 'services/Auth'
import { ROUTES } from 'constants/routes'
import { useWindowResize } from 'hooks/useWindowSize'
import { GlobalContext } from 'contexts/global-context'
import { GlobalContextType } from 'models'

import './Navbar.scss'

const Navbar = () => {
  const { user } = useContext(GlobalContext) as GlobalContextType
  const navigate = useNavigate()
  const [width] = useWindowResize()
  const elementRef = useRef(null)
  const [calculatedHeight, setCalculatedHeight] = useState('40px')

  useEffect(() => {
    const element = elementRef.current
    if (!element) {
      return
    }
    const computedStyle = window.getComputedStyle(element)
    const height = computedStyle.getPropertyValue('height')
    const parsedHeight = parseFloat(height)
    const eightyPercentHeight = parsedHeight * 0.8
    setCalculatedHeight(eightyPercentHeight.toString() + 'px')
  }, [])

  const onLogout = () => {
    logoutUser()
      .then(() => {
        handleLogout()
      })
      // TODO fix this, it is a hack for fake logout
      .catch(() => {
        handleLogout()
      })
  }

  const handleLogout = () => {
    clearSession()
    navigate(ROUTES.WELCOME.PATH)
  }

  return (
    <nav ref={elementRef} className='nav'>
      <div className='nav-container'>
        <Logo className='nav-logo' />
        <div className='nav-container-actions'>
          <div className='nav-container-user' style={{ gap: '20px', display: 'flex' }}>
            <div className='nav-fullname' style={{ margin: 'auto 0' }}>
              <p>{user.firstName + ' ' + user.lastName}</p>
            </div>
            <Avatar
              className='nav-avatar'
              image={user.photoUrl}
              height={calculatedHeight}
              width={calculatedHeight}
              backgroundColor='transparent'
            />
          </div>
          <Button
            text='Log out'
            className={cx(
              'btn-primary',
              width < 350 ? 'btn-small' : 'btn-field',
              'nav-container-actions-logout',
            )}
            onClick={onLogout}
            style={{
              fontFamily: 'var(--font-semi-bold)',
              fontSize: 'var(--font-size-m)',
              fontWeight: '700',
              lineHeight: '20px',
              backgroundColor: 'transparent',
              border: '1px hidden transparent',
            }}
          />
        </div>
      </div>
    </nav>
  )
}

export default Navbar
