import cx from 'classnames'

import TableHeader from './TableHeader'
import TableRows from './TableRows'
import { ColumnDefinitionType } from 'models'

import './Table.scss'

type TableProps<T, K extends keyof T> = {
  data: Array<T>
  columns: Array<ColumnDefinitionType<T, K>>
  className?: string
}

const style = {
  borderCollapse: 'collapse',
} as const

const Table = <T, K extends keyof T>({ data, columns, className }: TableProps<T, K>) => {
  return (
    <table className={cx('table', className)} style={style}>
      <TableHeader columns={columns} />
      <TableRows data={data} columns={columns} />
    </table>
  )
}

export default Table
