import { AxiosResponse } from 'axios'
import { Api } from 'api'

import { GET_INVOICES_LIST_URL } from 'constants/invoices'
import { IInvoicesListResponse } from 'models'

interface GetInvoicesListProps {
  teamId: string
}

const getInvoicesListUrl = GET_INVOICES_LIST_URL

export const getInvoicesList = (data: GetInvoicesListProps) => {
  return Api.get(`${getInvoicesListUrl}/${data.teamId}`).then(
    (response: AxiosResponse<IInvoicesListResponse>) => {
      return response.data.invoices
    },
  )
}
