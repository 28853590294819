import { FC } from 'react'

import cx from 'classnames'

import Avatar from 'components/Avatar'
import { ITeam } from 'models'
import { convertARGBToHex } from 'helpers'

import './TeamSelect.scss'

interface ITeamSelectProps {
  className?: string
  selectedTeam: ITeam
}

const TeamSelect: FC<ITeamSelectProps> = ({ className, selectedTeam }) => {
  return (
    <div className={cx('team-select', className)}>
      <div className={cx('team-select-inside', className)}>
        <Avatar
          className='team-select-inside-avatar'
          image={selectedTeam.logoUrl}
          firstColor={convertARGBToHex(selectedTeam.firstColor || '#ffffff')}
          secondColor={convertARGBToHex(selectedTeam.secondColor || '#000000')}
          placeholder='color'
          height='74px'
          width='74px'
        />
        <div className='team-select-inside-group'>
          <div className='team-select-inside-group-text'>
            <p>{selectedTeam.name}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeamSelect
