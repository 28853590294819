import { FC } from 'react'

interface IStarProps extends React.CSSProperties {
  filled: boolean
}

const Star: FC<IStarProps> = ({ filled, color, width, height }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width || '24'}
      height={height || '24'}
      viewBox='0 0 24 24'
      fill={filled ? color : 'none'}
      stroke={color}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path d='M12 2 L15.09 8.54 L22 9.27 L17 14 L18.18 20 L12 17.77 L5.82 20 L7 14 L2 9.27 L8.91 8.54 L12 2 Z' />
    </svg>
  )
}

export default Star
