import './Redirect.scss'

const Redirect = () => {
  return (
    <div className='redirect'>
      <p className='redirect-text'>Redirecting...</p>
    </div>
  )
}

export default Redirect
