import { Dispatch, FC, SetStateAction, useRef, useState } from 'react'

import cx from 'classnames'

import { useBoolean } from 'hooks/useBoolean'
import { Company, ICompany, IUser } from 'models'
import Button from 'components/Button'
import Input from 'components/Input'

import './CompanyDetails.scss'
import { createCompanyInfo, deleteCompanyInfo, updateCompanyInfo } from 'services/Payments'

interface ICompanyDetailsProps {
  className?: string
  company: Company | null
  setCompany: Dispatch<SetStateAction<Company | null>>
  user: IUser
}

interface ICompanyInfoProps {
  company: Company | null
}

const CompanyInfo: FC<ICompanyInfoProps> = ({ company }) => {
  return (
    <div className='company-info-data'>
      <p className='company-info-data-name'>
        <strong>Company name</strong>: {company?.name ?? ''}
      </p>
      <p className='company-info-data-address'>
        <strong>Company address</strong>: {company?.address ?? ''}
      </p>
      <p className='company-info-data-vat'>
        <strong>Company VAT number</strong>: {company?.vatNumber ?? ''}
      </p>
      <p className='company-info-data-email'>
        <strong>Company email</strong>: {company?.email ?? ''}
      </p>
    </div>
  )
}

const CompanyDetails: FC<ICompanyDetailsProps> = ({ className, company, setCompany, user }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editState, openEdit, closeEdit, toggleEdit] = useBoolean(false)
  const nameInputRef = useRef<HTMLInputElement | null>(null)
  const emailInputRef = useRef<HTMLInputElement | null>(null)
  const addressInputRef = useRef<HTMLInputElement | null>(null)
  const vatNumberInputRef = useRef<HTMLInputElement | null>(null)
  const [isEditHovered, setIsEditHovered] = useState<boolean>(false)
  const [isDeleteHovered, setIsDeleteHovered] = useState<boolean>(false)
  const [isUpdateHovered, setIsUpdateHovered] = useState<boolean>(false)

  const updateCompany = (data: ICompany) => {
    if (
      vatNumberInputRef.current &&
      nameInputRef.current &&
      emailInputRef.current &&
      addressInputRef.current
    ) {
      company
        ? updateCompanyInfo(data, company.id)
            .then((company) => {
              setCompany(company)
            })
            .catch((err) => {
              console.log(err)
            })
        : createCompanyInfo({ ...data, userId: user.id })
            .then((company) => {
              setCompany(company)
            })
            .catch((err) => {
              console.log(err)
            })
      toggleEdit()
    }
  }

  const deleteCompany = () => {
    company &&
      deleteCompanyInfo({ companyId: company.id })
        .then(() => {
          setCompany(null)
        })
        .catch((err) => console.log(err))
  }

  return (
    <div className={cx(className, 'company')}>
      {editState ? (
        <div className='company-edit'>
          <div className='company-edit-info'>
            <p className='company-edit-info-name'>
              <strong>Company name</strong>:{' '}
              <Input
                placeholder={company ? company.name : 'Enter company name'}
                ref={nameInputRef}
              />
            </p>
            <p className='company-edit-info-address'>
              <strong>Company address</strong>:
              <Input
                placeholder={company ? company.address : 'Enter company address'}
                ref={addressInputRef}
              />
            </p>
            <p className='company-edit-info-vat'>
              <strong>Company VAT number</strong>:
              <Input
                className='company-edit-info-vat-input'
                id='vat-number'
                placeholder={company ? company.vatNumber : 'Enter company VAT number'}
                ref={vatNumberInputRef}
                type='text'
              />
            </p>
            <p className='company-edit-info-email'>
              <strong>Company email</strong>:
              <Input
                placeholder={company ? company.email : 'Enter company email'}
                ref={emailInputRef}
              />
            </p>
          </div>
          <div className='company-edit-button'>
            <Button
              className='primary'
              text='Update company info'
              onClick={() =>
                updateCompany({
                  name: nameInputRef.current?.value ?? '',
                  address: addressInputRef.current?.value ?? '',
                  email: emailInputRef.current?.value ?? '',
                  vatNumber: vatNumberInputRef.current?.value ?? '',
                })
              }
              onMouseEnter={() => setIsUpdateHovered(true)}
              onMouseLeave={() => setIsUpdateHovered(false)}
              onTouchStart={() => setIsUpdateHovered(true)}
              onTouchEnd={() => setIsUpdateHovered(false)}
              style={{
                width: 'fit-content',
                padding: '15px 32px 15px 32px',
                border: isUpdateHovered ? '' : '2px solid #4B4B4B',
                color: '#4B4B4B',
                boxShadow: isUpdateHovered
                  ? '0px 4px 15px 0px #5D5FEF66, 0px -4px 15px 0px #EB000033'
                  : '',
              }}
            />
          </div>
        </div>
      ) : (
        <div className='company-info'>
          <CompanyInfo company={company} />
          <div className='company-info-buttons'>
            <Button
              className='primary'
              text='Edit company info'
              onClick={() => toggleEdit()}
              onMouseEnter={() => setIsEditHovered(true)}
              onMouseLeave={() => setIsEditHovered(false)}
              onTouchStart={() => setIsEditHovered(true)}
              onTouchEnd={() => setIsEditHovered(false)}
              style={{
                width: 'fit-content',
                padding: '15px 32px 15px 32px',
                border: isEditHovered ? '' : '2px solid #4B4B4B',
                color: '#4B4B4B',
                boxShadow: isEditHovered
                  ? '0px 4px 15px 0px #5D5FEF66, 0px -4px 15px 0px #EB000033'
                  : '',
              }}
            />
            {/* {company && ( */}
            <Button
              className='primary'
              text='Delete company info'
              onClick={() => deleteCompany()}
              onMouseEnter={() => setIsDeleteHovered(true)}
              onMouseLeave={() => setIsDeleteHovered(false)}
              onTouchStart={() => setIsDeleteHovered(true)}
              onTouchEnd={() => setIsDeleteHovered(false)}
              style={{
                width: 'fit-content',
                padding: '15px 32px 15px 32px',
                border: isDeleteHovered ? '' : '2px solid #4B4B4B',
                color: '#4B4B4B',
                boxShadow: isDeleteHovered
                  ? '0px 4px 15px 0px #5D5FEF66, 0px -4px 15px 0px #EB000033'
                  : '',
              }}
            />
            {/* )} */}
          </div>
        </div>
      )}
    </div>
  )
}

export default CompanyDetails
