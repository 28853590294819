import { useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { setCookie } from 'typescript-cookie'
import { useGoogleLogin } from '@react-oauth/google'
import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'

import Button from 'components/Button'
import AppleButton from 'components/AppleButton'
import { googleLoginIdToken, googleLoginToken, loginClient } from 'services/Auth'
import { useAuthSetup } from 'hooks/useAuthSetup'
import {
  ACCESS_CLIENT_TOKEN_KEY,
  OAUTH2_APP_BUTTON_TEXT,
  OAUTH2_ERROR_HAS_APPLE_ACCOUNT,
  OAUTH2_ERROR_HAS_GOOGLE_ACCOUNT,
  OAUTH2_ERROR_HAS_LOCAL_ACCOUNT,
  OAUTH2_GOOGLE_BUTTON_TEXT,
  OAUTH2_GOOGLE_FLOW,
  OAUTH2_GOOGLE_REDIRECT_URI,
} from 'constants/auth'
import { IAuthClient, ISocialAuthUser, ResponseErrorData } from 'models'
import { redirectUrlForAuthorizeServer } from 'helpers'

import './AuthSection.scss'

const AuthSection = () => {
  const [setupUser] = useAuthSetup()
  const [loginBtnHighlighted, setLoginBtnHighlighted] = useState(false)
  const [googleLoginBtnHighlighted, setGoogleLoginBtnHighlighted] = useState(false)
  const [appleLoginBtnHighlighted, setAppleLoginBtnHighlighted] = useState(false)
  const [browser, setBrowser] = useState('')

  const detectBrowser = () => {
    const userAgent = navigator.userAgent

    if (userAgent.includes('Chrome') && !userAgent.includes('Edg')) {
      return 'Chrome'
    } else if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
      return 'Safari'
    } else if (userAgent.includes('Firefox')) {
      return 'Firefox'
    } else if (userAgent.includes('Edg')) {
      return 'Edge'
    } else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
      return 'Opera'
    } else if (userAgent.includes('MSIE') || userAgent.includes('Trident')) {
      return 'Internet Explorer'
    } else {
      return 'Unknown'
    }
  }

  useEffect(() => {
    const userBrowser = detectBrowser()
    setBrowser(userBrowser)
  }, [])

  function checkPopupBlocked() {
    if (browser !== 'Safari') {
      return
    }

    const popup = window.open('', '', 'width=100,height=100')

    if (!popup || popup.closed || typeof popup.closed === 'undefined') {
      // Popup is blocked
      return true
    } else {
      // Popup is allowed
      popup.close()
      return false
    }
  }

  const isPopupBlocked = checkPopupBlocked()

  const initializeStandardLogin = () => {
    window.location.assign(redirectUrlForAuthorizeServer())
  }

  const initializeGoogleLogin = () => {
    loginClient()
      .then((data: IAuthClient) => {
        setCookie(ACCESS_CLIENT_TOKEN_KEY, data.access_token)
        googleAccountPrompt()
      })
      .catch((error: AxiosError) => {
        console.log(error)
      })
  }

  const googleAccountPrompt = useGoogleLogin({
    onSuccess: (googleResponse) =>
      googleLoginToken({ code: googleResponse.code })
        .then((googleUser: ISocialAuthUser) => {
          googleLoginVerify(googleUser.id_token)
        })
        .catch((error: AxiosError) => {
          console.log(error)
        }),
    flow: OAUTH2_GOOGLE_FLOW,
    redirect_uri: OAUTH2_GOOGLE_REDIRECT_URI,
  })

  const googleLoginVerify = (idToken: string) => {
    googleLoginIdToken({ idToken })
      .then(() => {
        setupUser()
      })
      .catch((responseError: AxiosError) => {
        const { response } = responseError
        const { error, error_description } = responseError.response?.data as ResponseErrorData
        if (
          response?.status === 400 &&
          (error === OAUTH2_ERROR_HAS_LOCAL_ACCOUNT ||
            error === OAUTH2_ERROR_HAS_GOOGLE_ACCOUNT ||
            error === OAUTH2_ERROR_HAS_APPLE_ACCOUNT)
        ) {
          alert(error_description + 'Please, try the highlighted login with the same account.')
          error.includes('DIFFERENT') && setLoginBtnHighlighted(true)
          error.includes('APPLE') && setAppleLoginBtnHighlighted(true)
        }
      })
  }

  return (
    <>
      <div className='auth-section'>
        <div className='auth-section-btn'>
          <Button
            text={OAUTH2_APP_BUTTON_TEXT}
            className={cx(
              'btn-primary btn-full',
              loginBtnHighlighted && 'btn-highlight',
              'auth-section-btn-login',
            )}
            onClick={initializeStandardLogin}
            style={{ height: '70px', fontSize: 'var(--font-size-s)' }}
          />
        </div>
        <div className='auth-section-btn'>
          <Button
            className={cx(
              'btn-secondary btn-full auth-section-btn-google',
              googleLoginBtnHighlighted && 'btn-highlight',
            )}
            onClick={initializeGoogleLogin}
            text={OAUTH2_GOOGLE_BUTTON_TEXT}
            style={{ height: '70px', maxHeight: '70px', fontSize: 'var(--font-size-s)' }}
          >
            <FontAwesomeIcon icon={faGoogle} size='2x' />
          </Button>
        </div>
        <div className='auth-section-btn'>
          <AppleButton
            setLoginBtnHighlighted={setLoginBtnHighlighted}
            setGoogleLoginBtnHighlighted={setGoogleLoginBtnHighlighted}
            setupUser={setupUser}
            appleLoginBtnHighlighted={appleLoginBtnHighlighted}
          />
        </div>
        {isPopupBlocked && (
          <div className='auth-section-popup'>
            <p>
              Please allow popups and redirections under Settings for your browser in order to be
              able to sign in with SSO
            </p>
          </div>
        )}
      </div>
    </>
  )
}

export default AuthSection
