import { FC, ReactElement, useState } from 'react'

import cx from 'classnames'

import TabItem from 'components/Tabs/TabItem'

import './Tabs.scss'

interface ITabsProps {
  className?: string
  children: ReactElement[]
  active: number
}

const Tabs: FC<ITabsProps> = ({ children, className, active }) => {
  const [selectedTab, setSelectedTab] = useState(active)

  const isActive = (index: number) => {
    return selectedTab === index
  }

  return (
    <div className={cx('tabs', className)}>
      <ul className={cx('tabs-titles', className + '-titles')}>
        {children.map((item, index) => (
          <TabItem
            title={item.props.title}
            key={`tab-link-${item.props.title}`}
            index={index}
            setSelectedTab={setSelectedTab}
            isDisabled={item.props.isDisabled}
            isActive={isActive(index)}
          />
        ))}
      </ul>
      {children[selectedTab]}
    </div>
  )
}

export default Tabs