import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Star from 'components/RatingStars/Star/Star'
import { PRICING } from 'constants/website'
import { useWindowResize } from 'hooks/useWindowSize'
import Button from 'components/Button'
import { ROUTES } from 'constants/routes'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFutbol } from '@fortawesome/free-solid-svg-icons'

import './Pricing.scss'

import '@fortawesome/fontawesome-free/css/all.css'
import Pill from 'components/Pill'

const Pricing: FC = () => {
  const [width] = useWindowResize()
  const navigate = useNavigate()
  const [isMouseInside, setIsMouseInside] = useState(false)
  const [isFirstChildVisible, setIsFirstChildVisible] = useState(false)

  const handleTouchStart = () => {
    setIsMouseInside(true)
  }

  const handleTouchEnd = () => {
    setIsMouseInside(false)
  }

  const handleMouseEnter = () => {
    setIsMouseInside(true)
  }

  const handleMouseLeave = () => {
    setIsMouseInside(false)
  }

  const handleClick = () => {
    navigate(ROUTES.WELCOME.PATH)
  }

  useEffect(() => {
    if (isMouseInside) {
      setIsFirstChildVisible(false)

      const timeout = setTimeout(() => {
        setIsFirstChildVisible(true)
      }, 1250)

      return () => clearTimeout(timeout)
    } else {
      const timeout = setTimeout(() => {
        setIsFirstChildVisible(false)
      }, 2000)

      return () => clearTimeout(timeout)
    }
  }, [isMouseInside])

  return (
    <div
      id='pricing'
      className='pricings'
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className='pricings-header'>
        <p>Choose your subscription plan</p>
      </div>
      <Pill className={`pricings-box ${isMouseInside || width < 700 ? '' : 'not-visible'}`}>
        {isFirstChildVisible || width < 700 ? (
          <div className='pricings-box-yearly'>
            <div className='pricings-box-yearly-star'>
              <Star
                filled={true}
                color={'#252525'}
                width={width > 550 ? '72px' : width > 450 ? '50px' : width > 350 ? '40px' : '30px'}
                height={width > 550 ? '72px' : width > 450 ? '50px' : width > 350 ? '40px' : '30px'}
              />
            </div>
            <div className='pricings-box-yearly-title'>
              <p>Yearly Plan</p>
            </div>
            <div className='pricings-box-yearly-price'>
              <div className='pricings-box-yearly-price-number'>
                <p>{PRICING.YEARLY}</p>
              </div>
              <div className='pricings-box-yearly-price-period'>
                <p>/Year</p>
              </div>
            </div>
            <div className='pricings-box-yearly-button'>
              <Button
                text='Choose a Plan'
                onClick={() => handleClick()}
                className='pricings-box-yearly-button-choose'
                style={{
                  width: width > 400 ? 'fit-content' : 'min-content',
                  height: '48px',
                  borderRadius: '35px',
                  padding:
                    width > 800
                      ? '15px 32px 15px 32px'
                      : width > 400
                      ? '7px 16px 7px 16px'
                      : '3px 5px 3px 5px',
                  background: '#000000',
                  color: '#FFFFFF',
                  lineHeight: '20px',
                  fontFamily: 'var(--font-bold)',
                  fontSize:
                    width > 800
                      ? 'var(--font-size-m)'
                      : width > 400
                      ? 'var(--font-size-s)'
                      : 'var(--font-size-xs)',
                  textAlign: 'center',
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        <Pill
          className='pricings-box-monthly'
          style={isFirstChildVisible ? { flex: 2 } : { flex: 1 }}
        >
          <div className='pricings-box-monthly-content'>
            <div className='pricings-box-monthly-content-icon'>
              <FontAwesomeIcon
                icon={faFutbol}
                size='3x'
                opacity={0.1}
                style={{
                  width:
                    width > 550 ? '72px' : width > 450 ? '50px' : width > 350 ? '40px' : '30px',
                  height:
                    width > 550 ? '72px' : width > 450 ? '50px' : width > 350 ? '40px' : '30px',
                }}
              />
            </div>
            <div className='pricings-box-monthly-content-title'>
              <p>Monthly Plan</p>
            </div>
            <div className='pricings-box-monthly-content-price'>
              <div className='pricings-box-monthly-content-price-number'>
                <p>{PRICING.MONTHLY}</p>
              </div>
              <div className='pricings-box-monthly-content-price-period'>
                <p>/Month</p>
              </div>
            </div>
            <div className='pricings-box-monthly-content-button'>
              <Button
                text='Choose a Plan'
                onClick={() => handleClick()}
                className='pricings-box-monthly-content-button-choose'
                style={{
                  width: width > 400 ? 'fit-content' : 'min-content',
                  height: '48px',
                  borderRadius: '35px',
                  padding:
                    width > 800
                      ? '15px 32px 15px 32px'
                      : width > 400
                      ? '7px 16px 7px 16px'
                      : '3px 5px 3px 5px',
                  background: '##FFFFFF',
                  color: '#000000',
                  lineHeight: '20px',
                  fontFamily: 'var(--font-bold)',
                  fontSize:
                    width > 800
                      ? 'var(--font-size-m)'
                      : width > 400
                      ? 'var(--font-size-s)'
                      : 'var(--font-size-xs)',
                  textAlign: 'center',
                }}
              />
            </div>
          </div>
        </Pill>
      </Pill>
    </div>
  )
}

export default Pricing
