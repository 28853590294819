import { AxiosResponse } from 'axios'

import { getCookie, removeCookie, setCookie } from 'typescript-cookie'

import {
  ACCESS_CLIENT_TOKEN_KEY,
  ACCESS_USER_TOKEN_KEY,
  GET_USERS_API_URL,
  GRANT_TYPE_AUTHORIZATION_CODE,
  GRANT_TYPE_CLIENT_CREDENTIALS,
  GRANT_TYPE_REFRESH_TOKEN,
  HEADERS_CONTENT_TYPE_FORM_URLENCODED,
  LOGOUT_USER_API_URL,
  OAUTH2_GOOGLE_LOGIN_TOKEN_URL,
  OAUTH2_GOOGLE_REDIRECT_URI,
  OAUTH2_SOCIAL_LOGIN_ID_TOKEN_URL,
  OAUTH2_ID_TOKEN,
  OAUTH2_APPLE_REDIRECT_URI,
  REDIRECT_TEAM_ID_KEY,
  REFRESH_TOKEN_KEY,
  USER_KEY,
  SOCIAL_LOGIN_PROVIDERS,
  SOCIAL_LOGIN_PROVIDER,
} from 'constants/auth'
import { Api } from 'api'
import { IAuthClient, ISocialAuthUser, ISocialAuthUserVerified, IAuthUser, IUser } from 'models'

interface UserLoginData {
  code: string
}

interface SocialUserLoginData {
  idToken: string
}

const apiUrl = process.env.REACT_APP_API_URL
const clientUrl = process.env.REACT_APP_CLIENT_URL

const CLIENT_CREDENTIALS: { [key: string]: { clientId: string; secret: string } } = {
  [SOCIAL_LOGIN_PROVIDERS.LOCAL]: {
    clientId: process.env.REACT_APP_CLIENT_ID as string,
    secret: process.env.REACT_APP_CLIENT_SECRET as string,
  },
  [SOCIAL_LOGIN_PROVIDERS.GOOGLE]: {
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID as string,
    secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET as string,
  },
  [SOCIAL_LOGIN_PROVIDERS.APPLE]: {
    clientId: process.env.REACT_APP_APPLE_CLIENT_ID as string,
    secret: '',
  },
}

const grantTypeClientCredentials = GRANT_TYPE_CLIENT_CREDENTIALS
const grantTypeAuthorizationCode = GRANT_TYPE_AUTHORIZATION_CODE
const grantTypeRefreshToken = GRANT_TYPE_REFRESH_TOKEN

const headersContentTypeFormUrlencoded = HEADERS_CONTENT_TYPE_FORM_URLENCODED

const redirectUri = `${clientUrl}authorized`

const loginClientUrl = `${apiUrl}oauth/token`
const loginUserUrl = `${apiUrl}oauth/token`
const refreshUserAccessTokenUrl = `${apiUrl}oauth/token`

export const loginUserPromptUrl = `${apiUrl}oauth/authorize?response_type=code&client_id=${
  CLIENT_CREDENTIALS[SOCIAL_LOGIN_PROVIDERS.LOCAL].clientId
}&scope=all&redirect_uri=${redirectUri}&response_type=code`

const SOCIAL_URLS: { [key: string]: { redirectUri: string; loginTokenUrl: string } } = {
  [SOCIAL_LOGIN_PROVIDERS.GOOGLE]: {
    redirectUri: OAUTH2_GOOGLE_REDIRECT_URI,
    loginTokenUrl: OAUTH2_GOOGLE_LOGIN_TOKEN_URL,
  },
  [SOCIAL_LOGIN_PROVIDERS.APPLE]: {
    redirectUri: OAUTH2_APPLE_REDIRECT_URI,
    loginTokenUrl: '',
  },
}

const socialLoginIdTokenUrl = `${apiUrl}${OAUTH2_SOCIAL_LOGIN_ID_TOKEN_URL}`

const logoutUserUrl = LOGOUT_USER_API_URL

const getLoggedInUserUrl = GET_USERS_API_URL

export const loginClient = () => {
  const params = new URLSearchParams({
    client_id: CLIENT_CREDENTIALS[SOCIAL_LOGIN_PROVIDERS.LOCAL].clientId,
    client_secret: CLIENT_CREDENTIALS[SOCIAL_LOGIN_PROVIDERS.LOCAL].secret,
  }).toString()

  return Api.post(loginClientUrl + '?grant_type=' + grantTypeClientCredentials, params, {
    headers: {
      'Content-type': headersContentTypeFormUrlencoded,
    },
    auth: {
      username: CLIENT_CREDENTIALS[SOCIAL_LOGIN_PROVIDERS.LOCAL].clientId,
      password: CLIENT_CREDENTIALS[SOCIAL_LOGIN_PROVIDERS.LOCAL].secret,
    },
  }).then(async (response: AxiosResponse<IAuthClient>) => {
    return response.data
  })
}

export const loginUser = (data: UserLoginData) => {
  const { code } = data

  const params = new URLSearchParams()
  params.append('client_id', CLIENT_CREDENTIALS[SOCIAL_LOGIN_PROVIDERS.LOCAL].clientId)
  params.append('client_secret', CLIENT_CREDENTIALS[SOCIAL_LOGIN_PROVIDERS.LOCAL].secret)

  return Api.post(
    loginUserUrl +
      '?grant_type=' +
      grantTypeAuthorizationCode +
      '&code=' +
      code +
      '&redirect_uri=' +
      redirectUri,
    params,
    {
      headers: {
        'Content-type': headersContentTypeFormUrlencoded,
      },
      auth: {
        username: CLIENT_CREDENTIALS[SOCIAL_LOGIN_PROVIDERS.LOCAL].clientId,
        password: CLIENT_CREDENTIALS[SOCIAL_LOGIN_PROVIDERS.LOCAL].secret,
      },
    },
  ).then(async (response: AxiosResponse<IAuthUser>) => {
    const accessUserToken = response.data.access_token
    const refreshToken = response.data.refresh_token

    setCookie(ACCESS_USER_TOKEN_KEY, accessUserToken)
    setCookie(REFRESH_TOKEN_KEY, refreshToken)

    return response.data
  })
}

export const googleLoginToken = (data: UserLoginData) => {
  const { code } = data

  return Api.post(
    SOCIAL_URLS[SOCIAL_LOGIN_PROVIDERS.GOOGLE].loginTokenUrl +
      '?code=' +
      code +
      '&grant_type=authorization_code' +
      '&client_id=' +
      CLIENT_CREDENTIALS[SOCIAL_LOGIN_PROVIDERS.GOOGLE].clientId +
      '&client_secret=' +
      CLIENT_CREDENTIALS[SOCIAL_LOGIN_PROVIDERS.GOOGLE].secret +
      '&redirect_uri=' +
      OAUTH2_GOOGLE_REDIRECT_URI,
    {
      headers: {
        'Content-type': headersContentTypeFormUrlencoded,
      },
      auth: {
        username: CLIENT_CREDENTIALS[SOCIAL_LOGIN_PROVIDERS.GOOGLE].clientId,
        password: CLIENT_CREDENTIALS[SOCIAL_LOGIN_PROVIDERS.GOOGLE].secret,
      },
    },
  ).then(async (response: AxiosResponse<ISocialAuthUser>) => {
    return response.data
  })
}

export const googleLoginIdToken = (data: SocialUserLoginData) => {
  return socialLoginIdToken(data, SOCIAL_LOGIN_PROVIDERS.GOOGLE)
}

export const appleLoginIdToken = (data: SocialUserLoginData) => {
  return socialLoginIdToken(data, SOCIAL_LOGIN_PROVIDERS.APPLE)
}

const socialLoginIdToken = (data: SocialUserLoginData, provider: string) => {
  const { idToken } = data
  const accessClientToken = getCookie(ACCESS_CLIENT_TOKEN_KEY)
  const body = { [OAUTH2_ID_TOKEN]: idToken, [SOCIAL_LOGIN_PROVIDER]: provider }

  return Api.post(socialLoginIdTokenUrl, body, {
    headers: {
      Authorization: `Bearer ${accessClientToken}`,
      'Content-type': headersContentTypeFormUrlencoded,
    },
  }).then(async (response: AxiosResponse<ISocialAuthUserVerified>) => {
    const accessUserToken = response.data.access_token
    const refreshToken = response.data.refresh_token

    setCookie(ACCESS_USER_TOKEN_KEY, accessUserToken)
    setCookie(REFRESH_TOKEN_KEY, refreshToken)
    return response.data
  })
}

export const refreshUserAccessToken = () => {
  const refreshToken = getCookie(REFRESH_TOKEN_KEY) as string

  const params = new URLSearchParams()
  params.append('grant_type', grantTypeRefreshToken)
  params.append('refresh_token', refreshToken)

  return Api.post(refreshUserAccessTokenUrl, params, {
    headers: {
      'Content-type': headersContentTypeFormUrlencoded,
    },
    auth: {
      username: CLIENT_CREDENTIALS[SOCIAL_LOGIN_PROVIDERS.LOCAL].clientId,
      password: CLIENT_CREDENTIALS[SOCIAL_LOGIN_PROVIDERS.LOCAL].secret,
    },
  }).then(async (response: AxiosResponse<IAuthUser>) => {
    removeCookie(REFRESH_TOKEN_KEY)
    const newAccessUserToken = response.data.access_token
    const newRefreshToken = response.data.refresh_token

    setCookie(ACCESS_USER_TOKEN_KEY, newAccessUserToken)
    setCookie(REFRESH_TOKEN_KEY, newRefreshToken)

    return response.data
  })
}

export const getLoggedInUser = () => {
  return Api.get(getLoggedInUserUrl).then((response: AxiosResponse<IUser>) => {
    return response.data
  })
}

export const getIsAuthenticated = () => {
  return !!getCookie(ACCESS_USER_TOKEN_KEY)
}

export const logoutUser = () => {
  return Api.post(logoutUserUrl).then((response: AxiosResponse) => {
    return response.data
  })
}

export const clearSession = () => {
  localStorage.removeItem(USER_KEY)
  removeCookie(REDIRECT_TEAM_ID_KEY)
  removeCookie(ACCESS_CLIENT_TOKEN_KEY)
  removeCookie(ACCESS_USER_TOKEN_KEY)
  removeCookie(REFRESH_TOKEN_KEY)
}
