import Account from 'components/Account'
import Teams from 'components/Teams'

import './Portal.scss'

const Portal = () => {
  return (
    <>
      <Account />
      <Teams />
    </>
  )
}

export default Portal
